// @ts-nocheck
import { TIME_5MIN } from 'v2/utils/constants';
import { OBJECT_TYPES, WIDGET_BASE } from 'v2/constants/objectTypes';
import { FIELD_TYPES } from 'v2/constants/fieldTypes';
import { FIELD_RULES, FIELD_VALIDATORS } from 'v2/constants/fieldValidators';

export const FORM_SCHEMES = {
  LOGIN: () => [{
    label: 'E-Mail',
    name: 'login',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.EMAIL],
  }, {
    label: 'Password',
    name: 'password',
    type: FIELD_TYPES.TEXT_PASSWORD,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.PASSWORD, FIELD_RULES.MINLENGTH_8],
  }],
  ADD_COMPANY: ({ companiesTypes }) => [{
    label: 'New company type',
    name: 'type',
    items: companiesTypes,
    type: FIELD_TYPES.SELECT_SINGLE,
    value: '',
    defaultValue: '',
    rules: [FIELD_RULES.REQUIRED],
  }, {
    label: 'Company name',
    name: 'name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.COMPANY_NAME],
  }, {
    label: 'Contact E-Mail',
    name: 'contact_email',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.EMAIL],
  }, {
    label: 'Contact Address',
    name: 'contact_address',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100],
  }],
  ADD_LICENSE: () => [{
    label: 'License Key',
    name: 'key',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.LICENSE],
  }],
  ADD_TOKEN: () => [{
    label: 'Token name',
    name: 'token_name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.COMPANY_NAME],
  }],
  ADD_TAG: () => [{
    label: 'Tag name',
    name: 'name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.TAG_NAME],
  }],
  REGISTRATION_USER: (email) => [{
    label: 'E-Mail',
    name: 'email',
    type: FIELD_TYPES.TEXT_INPUT,
    value: email,
    disabled: true,
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.EMAIL],
  }, {
    label: 'First name',
    name: 'first_name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.USER_NAME],
  }, {
    label: 'Last name',
    name: 'last_name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.USER_NAME],
  },
  {
    label: 'Password',
    name: 'password',
    type: FIELD_TYPES.TEXT_PASSWORD,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_8, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.PASSWORD],
  }],
  ADD_USER: ({ usersTypes, addAdmin }) => [
    {
      label: 'E-Mail',
      name: 'email',
      type: FIELD_TYPES.TEXT_INPUT,
      value: '',
      rules: [FIELD_RULES.REQUIRED, FIELD_RULES.EMAIL],
    }, {
      label: 'Role',
      name: 'role',
      items: usersTypes,
      type: FIELD_TYPES.SELECT_SINGLE,
      value: addAdmin ? usersTypes[0].value : '',
      defaultValue: '',
      disabled: addAdmin,
      rules: [FIELD_RULES.REQUIRED],
    }],
  CREATE_WIDGET: (widgetType) => [{
    label: 'Widget title',
    name: 'name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: '',
    rules: [FIELD_RULES.REQUIRED],
  }, {
    label: 'Objects type',
    name: 'objectType',
    items: WIDGET_BASE[widgetType],
    type: FIELD_TYPES.SELECT_SINGLE,
    value: OBJECT_TYPES.FACE,
  }],
  CHOOSE_TAG: (oldTag, items) => [{
    label: 'Tag',
    name: 'tag',
    items,
    type: FIELD_TYPES.SELECT_MULTIPLE,
    value: oldTag,
  }],
  CHANGE_PASSWORD: () => [{
    label: 'Old Password',
    name: 'old_password',
    type: FIELD_TYPES.TEXT_PASSWORD,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.PASSWORD, FIELD_RULES.MINLENGTH_8],
  }, {
    label: 'New Password',
    name: 'new_password',
    type: FIELD_TYPES.TEXT_PASSWORD,
    value: '',
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.PASSWORD, FIELD_RULES.MINLENGTH_8],
  }],
  EDIT_USER: (first_name, last_name) => [{
    label: 'First name',
    name: 'first_name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: first_name,
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.USER_NAME],
  }, {
    label: 'Last name',
    name: 'last_name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: last_name,
    rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.USER_NAME],
  }],
  CLUSTER_SUBSCRIPTION: (name, subscriptions) => [{
    label: 'Subscription name',
    name: 'name',
    type: FIELD_TYPES.TEXT_INPUT,
    value: name,
    rules: [
      FIELD_RULES.REQUIRED,
      FIELD_RULES.MINLENGTH_2,
      FIELD_RULES.MAXLENGTH_100,
      FIELD_RULES.SUBSCRIPTION_NAME,
      FIELD_VALIDATORS[FIELD_RULES.CLUSTER_NOTIFICATION_DUPLICATE](subscriptions),
    ],
  }],
  EDIT_TIMEZONE: (value, items) => [{
    label: 'Time zone',
    name: 'timezone',
    type: FIELD_TYPES.SELECT_SINGLE,
    value,
    defaultValue: '',
    items,
    rules: [FIELD_RULES.REQUIRED],
  }],
  ADDITIONAL_INFORMATION: (suggestions, name, notes, tags) => [
    {
      label: 'Cluster name',
      name: 'name',
      type: FIELD_TYPES.AUTO_COMPLETE,
      value: name || '',
      items: suggestions,
      rules: [FIELD_RULES.MAXLENGTH_100, FIELD_RULES.CLUSTER_NAME],
    },
    {
      label: 'Objects details',
      name: 'notes',
      type: FIELD_TYPES.TEXT_AREA,
      rows: 4,
      value: notes || '',
      size: 'medium',
      rules: [FIELD_RULES.MAXLENGTH_500],
    },
    // Will be hidden until release
    // {
    //   label: 'Tags',
    //   name: 'tags',
    //   type: FIELD_TYPES.TAGS_AUTO_COMPLETE,
    //   value: tags || [],
    //   tags: tags || [],
    //   rules: [FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.TAG_NAME],
    // },
  ],
  SEARCH_CAMERAS: (value) => [{
    label: 'Cameras / Locations',
    name: 'devices',
    type: FIELD_TYPES.SELECT_CAMERAS,
    value,
    defaultValue: {},
  }],
  SEARCH_DATES: ({ timestamp_start, timestamp_end } = {}) => [{
    label: 'Start Period',
    placeHolder: 'From First Object',
    name: 'timestamp_start',
    type: FIELD_TYPES.SELECT_DATE,
    value: timestamp_start,
    defaultValue: null,
    onChange: (newValue, { timestamp_end }) => {
      if (timestamp_end && newValue && newValue > timestamp_end) {
        return timestamp_end - TIME_5MIN;
      }

      return newValue;
    },
  }, {
    label: 'End Period',
    placeHolder: 'To Last Object',
    name: 'timestamp_end',
    type: FIELD_TYPES.SELECT_DATE,
    value: timestamp_end,
    defaultValue: null,
    onChange: (newValue, { timestamp_start }) => {
      if (timestamp_start && newValue && newValue < timestamp_start) {
        return timestamp_start + TIME_5MIN;
      }

      return newValue;
    },
  }],
  SEARCH_ORDERING: (value) => [
    {
      label: 'Order results by',
      name: 'orderings',
      type: FIELD_TYPES.SELECT_OBJECT,
      items: [
        {
          value: { timestamp: 'timestamp desc' },
          name: 'New to old',
        },
        {
          value: { timestamp: 'timestamp' },
          name: 'Old to new',
        },
      ],
      value: value || { timestamp: 'timestamp desc' },
      defaultValue: { timestamp: 'timestamp desc' },
      showClear: true,
    },
  ],
  SEARCH_COMMON_FILTERS: ({ image_quality, name, notes } = {}) => [
    {
      items: [
        { name: 'Only filled', value: '*' },
        { name: 'Specific name', value: '' },
      ],
      label: 'Cluster\'s name',
      name: 'name',
      type: FIELD_TYPES.TEXT_COMBO,
      value: name,
      defaultValue: '',
    }, {
      items: [
        { name: 'Only filled', value: '*' },
        { name: 'Specific details', value: '' },
      ],
      label: 'Object\'s details',
      name: 'notes',
      type: FIELD_TYPES.TEXT_COMBO,
      value: notes,
      defaultValue: '',
    }, {
      label: 'Source image quality',
      name: 'image_quality',
      type: FIELD_TYPES.SELECT_SINGLE,
      items: [
        {
          value: '',
          name: 'All',
        },
        {
          value: '0',
          name: 'Good',
        },
        {
          value: '1',
          name: 'Bad',
        },
      ],
      value: image_quality,
      defaultValue: '0',
      showClear: true,
    },
  ],
  SEARCH_OBJECT_TYPE_FILTERS: {
    [OBJECT_TYPES.FACE]: ({ face_gender, face_age } = {}) => [
      {
        name: 'object_filter_type',
        value: OBJECT_TYPES.FACE,
      },
      {
        name: 'face_age',
        type: FIELD_TYPES.SLIDER_RANGE,
        label: 'Age',
        value: face_age,
        defaultValue: '',
        range: [0, 100],
        onChange: (value) => (value.join() === [0, 100].join() ? '' : value),
      },
      {
        name: 'face_gender',
        type: FIELD_TYPES.SELECT_SINGLE,
        label: 'Gender',
        value: face_gender,
        defaultValue: '',
        items: [
          { name: 'All', value: '' },
          { name: 'male', value: '1' },
          { name: 'female', value: '0' },
        ],
        showClear: true,
      },
    ],
    [OBJECT_TYPES.VEHICLE]: ({ vehicle_type, vehicle_license_plate } = {}) => [
      {
        name: 'object_filter_type',
        value: OBJECT_TYPES.VEHICLE,
      },
      {
        name: 'vehicle_type',
        type: FIELD_TYPES.SELECT_SINGLE,
        label: 'Vehicle type',
        value: vehicle_type,
        defaultValue: '',
        items: [
          {
            name: 'All',
            value: '',
          },
          {
            name: 'Cab',
            value: 'Cab',
          },
          {
            name: 'Hatchback',
            value: 'Hatchback',
          },
          {
            name: 'Minivan',
            value: 'Minivan',
          },
          {
            name: 'Sedan',
            value: 'Sedan',
          },
          {
            name: 'SUV',
            value: 'SUV',
          },
          {
            name: 'Truck',
            value: 'Truck',
          },
          {
            name: 'Van',
            value: 'Van',
          },
          {
            name: 'Wagon',
            value: 'Wagon',
          },
        ],
        showClear: true,
      },
      {
        name: 'vehicle_license_plate',
        type: FIELD_TYPES.TEXT_COMBO,
        label: 'Vehicle license plate',
        value: vehicle_license_plate,
        defaultValue: '',
        items: [
          {
            name: 'Only filled',
            value: '*',
          },
          {
            name: 'Specific license plate',
            value: '',
          },
        ],
      },
    ],
    [OBJECT_TYPES.PERSON]: () => [],
  },
  WATCH_LISTS_ADD: () => [
    {
      label: 'Watch List Name',
      name: 'name',
      type: FIELD_TYPES.TEXT_INPUT,
      value: '',
      defaultValue: '',
      rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.CLUSTER_NAME],
      showClear: true,
    },
    {
      name: 'object_type',
      type: FIELD_TYPES.SELECT_SINGLE,
      label: 'Object Type',
      value: '',
      defaultValue: '',
      items: [
        { name: 'Face', value: OBJECT_TYPES.FACE },
        { name: 'Vehicle', value: OBJECT_TYPES.VEHICLE },
      ],
      displayEmpty: false,
      rules: [FIELD_RULES.REQUIRED],
    },
  ],
  WATCH_LISTS_EDIT: ({ name } = {}) => [
    {
      label: 'Watch List Name',
      name: 'name',
      type: FIELD_TYPES.TEXT_INPUT,
      value: name,
      defaultValue: '',
      rules: [FIELD_RULES.REQUIRED, FIELD_RULES.MINLENGTH_2, FIELD_RULES.MAXLENGTH_100, FIELD_RULES.CLUSTER_NAME],
      showClear: true,
    },
  ],
  WATCH_LIST_OBJECT_TYPE_FILTERS: {
    [OBJECT_TYPES.FACE]: ({ name, person_age, person_gender } = {}) => [
      {
        label: 'Cluster\'s name',
        name: 'name',
        type: FIELD_TYPES.TEXT_INPUT,
        value: name,
        defaultValue: '',
        rules: [FIELD_RULES.MAXLENGTH_100, FIELD_RULES.CLUSTER_NAME],
        showClear: true,
      },
      {
        name: 'person_age',
        type: FIELD_TYPES.SLIDER_RANGE,
        label: 'Age',
        value: person_age,
        defaultValue: '',
        range: [0, 100],
        onChange: (value) => (value.join() === [0, 100].join() ? '' : value),
      },
      {
        name: 'person_gender',
        type: FIELD_TYPES.SELECT_SINGLE,
        label: 'Gender',
        value: person_gender,
        defaultValue: '',
        items: [
          { name: 'All', value: '' },
          { name: 'male', value: '1' },
          { name: 'female', value: '0' },
        ],
        showClear: true,
      },
    ],
    [OBJECT_TYPES.VEHICLE]: ({ vehicle_type, vehicle_license_plate } = {}) => [
      {
        name: 'vehicle_type',
        type: FIELD_TYPES.SELECT_SINGLE,
        label: 'Vehicle type',
        value: vehicle_type,
        defaultValue: '',
        items: [
          {
            name: 'All',
            value: '',
          },
          {
            name: 'Cab',
            value: 'Cab',
          },
          {
            name: 'Hatchback',
            value: 'Hatchback',
          },
          {
            name: 'Minivan',
            value: 'Minivan',
          },
          {
            name: 'Sedan',
            value: 'Sedan',
          },
          {
            name: 'SUV',
            value: 'SUV',
          },
          {
            name: 'Truck',
            value: 'Truck',
          },
          {
            name: 'Van',
            value: 'Van',
          },
          {
            name: 'Wagon',
            value: 'Wagon',
          },
        ],
        showClear: true,
      },
      {
        label: 'Vehicle license plate',
        name: 'vehicle_license_plate',
        type: FIELD_TYPES.TEXT_INPUT,
        value: vehicle_license_plate,
        defaultValue: '',
        rules: [FIELD_RULES.MAXLENGTH_100],
        showClear: true,
      },
    ]
  },
};
