// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box, CircularProgress, createStyles, Grid, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import Layout from 'partials/Layout';
import { TFace, TVehicle } from 'pages/appearance/Appearance';
import AdditionalInformation from 'v2/components/AdditionalInformation';
import api from 'app/api';
import PageNotFound from 'pages/PageNotFound';
import { useSnackbar } from 'notistack';
import { capitalizeFirstLetter } from 'v2/utils/functions';
import MainPhotoCard from 'components/MainPhotoCard';
import UIPhotoCard from 'v2/ui-kit/UIPhotoCard/UIPhotoCard';
import { MODE_PRODUCTION } from 'v2/utils/constants';
import { useBackdrop } from '../v2/providers/BackdropProvider';

const DEFAULT_PAGE_SIZE = Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE || '32');

const useStyles = makeStyles((theme) => createStyles({
  stickyContainer: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '.75rem',
      marginTop: '70px',
    },
  },
  totalAppearance: {
    display: 'flex',
    paddingLeft: 10,
  },
  totalAppearanceTitle: {
    fontSize: 18,
  },
  preloaderWrapper: {
    height: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // preloader: {
  //   textAlign: 'center',
  // },
  relatedPreloader: {
    paddingTop: '10%',
  },
  // infoContainer: {
  //   zIndex: 2,
  //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12)',
  //   [theme.breakpoints.down('sm')]: {
  //     padding: '.75rem',
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     position: 'unset',
  //   },
  // },
  paper: {
    // background: vars.primaryBackground,
    padding: '1rem',
    height: '100%',
    minHeight: 683,
  },
  title: {
    lineHeight: '1rem',
    fontSize: 16,
  },
  chartButton: {
    textTransform: 'initial',
    fontSize: '0.975rem',
  },
  chartButtonIcon: {
    marginRight: '.25rem',
  },
  // cardsContainer: {
  //   width: '100%',
  //   display: 'grid',
  //   paddingTop: '1rem',
  //   gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
  //   gridAutoRows: 'minmax(220px, auto)',
  //   gridGap: '1rem',
  //   [theme.breakpoints.down('sm')]: {
  //     padding: '.75rem',
  //   },
  // },
  formInput: {
    // backgroundColor: vars.backgroundInput,
    '& fieldset': {
      // border: `1px solid ${vars.primaryBorderColor}`,
    },
  },
}));

export type FormattedAppearanceProps = {
  type?: string;
  image_url: string;
  parent_id: number;
  timestamp: string;
  faceAppearance?: TFace;
  vehicleAppearance?: TVehicle;
  camera_name?: string;
  id: number;
};

const AppearanceDetails = () => {
  const classes = useStyles();
  const { id }: any = useParams();
  const history = useHistory();
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const scrollContainer = useRef(null);
  const [appearance, setAppearance] = useState({});
  const [relatedAppearances, setRelatedAppearance] = useState([]);
  const [pagination, setPagination] = useState();
  const [parentId, setParentId] = useState();
  const [appearancesLoading, setAppearancesLoading] = useState(true);
  const [touchBottom, setTouchBottom] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const showLabels = () => ['vehicle'].includes(appearance.type);
  const pageHeaderTitle = appearance.type ? capitalizeFirstLetter(appearance.type) : '';
  const showSimilar = !(appearance.type === 'vehicle' && MODE_PRODUCTION) && appearance.cluster_size > 1;

  const onResize = () => {
    if (scrollContainer.current?.scrollHeight === scrollContainer.current?.offsetHeight && !touchBottom) {
      fetchMore();
    }
  };

  useEffect(() => {
    if (relatedAppearances.length > 0) {
      window.addEventListener('resize', onResize);
      onResize();
    }
    return () => removeEventListener('resize', onResize);
  }, [relatedAppearances]);

  const fetchMore = () => {
    setLoadingData(true);

    const offset = pagination ? pagination.offset + DEFAULT_PAGE_SIZE : 0;
    const paginationQuery = `&filters=object_type:${appearance.type}&pgsize=${DEFAULT_PAGE_SIZE}&pgoffset=${offset}`;

    api.get(`/object-manager/search?filters=parent_id:${parentId}${paginationQuery}`).then((response) => {
      if (response.data.items.length < DEFAULT_PAGE_SIZE) {
        setTouchBottom(true);
      } else {
        setPagination(response.data.pagination);
      }

      setRelatedAppearance([...relatedAppearances, ...response.data.items]);
      setLoadingData(false);
    });
  };

  const fetchObject = () => {
    setAppearancesLoading(true);
    showBackdrop('Loading data');
    api
      .get(`/object-manager/objects/${id}`)
      .then((response) => {
        setRelatedAppearance([]);
        setPagination();
        setAppearance({
          ...response.data,
          camera_id: response.data.camera,
        });
        setAppearancesLoading(false);
        setParentId(response.data.parent_id || response.data.id);
      })
      .catch((err) => {
        console.error('some error occurred');
        console.error(err);
        setAppearancesLoading(false);
      })
      .finally(hideBackdrop);
  };

  useEffect(() => {
    fetchObject();
  }, [id]);

  useEffect(() => {
    if (parentId && showSimilar) {
      fetchMore();
    }
  }, [appearance, parentId]);

  return (
    <Layout title={pageHeaderTitle} hasBackIcon={history.length > 2}>
      <Box width={1} p={2} overflow="auto" id="LayoutContent" ref={scrollContainer}>
        {
          appearancesLoading && (
            <Box p={8} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={100} />
            </Box>
          )
        }
        {
          appearance.id === undefined && !appearancesLoading && (
            <PageNotFound />
          )
        }
        {
          !appearancesLoading && appearance.id && (
            <>
              <Grid
                container
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid item xs={12} sm={6} lg={3} xl={2}>
                  <MainPhotoCard
                    object={appearance}
                    bigPhoto
                    fullInfo
                    allowDetails
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={9} xl={10}>
                  <AdditionalInformation
                    id={id}
                    parentId={parentId}
                    type={appearance.type}
                    name={appearance.meta.name}
                    notes={appearance.meta.notes}
                    fetchObject={fetchObject}
                    tags={appearance.tags}
                  />
                </Grid>
                {showSimilar && (
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    lg={12}
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.totalAppearance}
                  >
                    <Grid item lg={9} md={8} sm={6} xs={12}>
                      <Typography className={classes.totalAppearanceTitle}>
                        Similar objects
                        (
                        {appearance.cluster_size}
                        )
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {showSimilar && (
                <InfiniteScroll
                  dataLength={relatedAppearances.length}
                  next={fetchMore}
                  hasMore={!touchBottom}
                  scrollableTarget="LayoutContent"
                  scrollThreshold="200px"
                  loader={loadingData && (
                    <Box p={8} display="flex" alignItems="center" justifyContent="center">
                      <CircularProgress size={100} />
                    </Box>
                  )}
                >
                  <Box mt={2}>
                    <Grid className="UICardsContainer">
                      {relatedAppearances.map((object) => <UIPhotoCard key={object.id} object={object} showMeta />)}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              )}
            </>
          )
        }
      </Box>
    </Layout>
  );
};

export default AppearanceDetails;
