// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, createStyles, Divider, Grid, makeStyles, Typography
} from '@material-ui/core';

import api from 'app/api';
import { componentMapping } from 'pages/dashboard/Dashboard';
import { colors } from 'Theme';
// import { useSelector } from 'react-redux';
import RealtimeSwitch from 'components/common/RealtimeSwitch';
import debounce from 'lodash/debounce';
import { useBackdrop } from 'v2/providers/BackdropProvider';
import { prepareGetQuery } from '../../utils/chart';
import { parseStringFilters } from '../../components/Dashboard/DashboardChart';
import SmallDisplayAlert from './SmallDisplayAlert';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: '2rem',
    width: '100%',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      padding: '.75rem',
      marginTop: '70px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.dark2,
    height: '100vh',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderBottom: `1px solid ${colors.borders}`,
    width: '100%',
  },
  blockHeaderLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  widgetHead: {
    fontSize: 18,
  },
  widgetContent: {
    position: 'relative',
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  preloaderWrapper: {
    height: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preloader: {
    // paddingTop: '40%',
  },
}),);

const SharedWidgets = () => {
  const { entityID } = useParams();
  const [widget, setWidget] = useState();
  const [error, setError] = useState(null);
  const [isSmallDisplay, setIsSmallDisplay] = useState(true);
  // const online = useSelector((state) => state.networkStatus.data.status);
  const { showBackdrop, hideBackdrop } = useBackdrop();

  const debouncedPatch = useCallback(debounce((newState = {}, loader) => {
    loader && showBackdrop('Update widget');

    return api
      .patch(`/layout-manager/widgets/${entityID}`, { state: { ...widget.state, ...newState } })
      .then(() => reloadWidget())
      .catch(() => {})
      .finally(() => {
        loader && hideBackdrop();
      })
  }, 500), [widget]);

  useEffect(() => {
    if (widget) {
      document.title = widget.title;
    }
  }, [widget])

  function checkDisplay() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width < 320 || height < 240) {
      setIsSmallDisplay(true)
    } else {
      setIsSmallDisplay(false)
    }
  }

  useEffect(() => {
    checkDisplay();
    window.addEventListener('resize', () => {
      checkDisplay();
    });
    return () => window.removeEventListener('resize', () => {
      checkDisplay();
    });
  }, []);

  const reloadWidget = () => api.get(`/layout-manager/widgets/${entityID}`).then(({ data }) => {
    const filtersObject = parseStringFilters(data.filters);

    const selectedIds = {
      cameras: filtersObject.selectedIds && Array.isArray(filtersObject.selectedIds.cameras) ? filtersObject.selectedIds.cameras : [],
      locations: filtersObject.selectedIds && Array.isArray(filtersObject.selectedIds.locations) ? filtersObject.selectedIds.locations : [],
    };

    const makeURL = (selectedIds: any, filtersObject: any) => [
      ...selectedIds.cameras.map((item: string) => `filters=camera:${item}`),
      ...Object.entries(filtersObject).filter(([key, value]) => !!value && key !== 'selectedIds').map(([key, value]) => `filters=${key}:${value}`),
    ].join('&')

    setWidget({
      component: componentMapping[data.type],
      id: data.id,
      // size: data.size[1] * 6,
      title: data.name,
      isRealtime: data.online,
      timeslice: data.timeslice,
      filters: data.filters,
      websocket_url: data.websocket_url,
      socketURL: prepareGetQuery(`${data.websocket_url}&${makeURL(selectedIds, filtersObject)}`),
      httpURL: prepareGetQuery(`/object-manager/search/?pgsize=32&${makeURL(selectedIds, filtersObject)}`),
      objectType: filtersObject.object_type,
      type: data.type,
      filtersObject,
      state: data.state,
      legend: data.state.legend,
      cameras: selectedIds.cameras,
      selectedIds,
      debouncedPatch,
    });
  })

  useEffect(() => {
    showBackdrop('Loading widget');
    setError();
    reloadWidget()
      .catch(({ response: { data } }) => setError(data))
      .finally(() => hideBackdrop())
  }, [entityID]);

  const handleRealtimeSwitch = (realtime) => {
    if (!widget) {
      return;
    }

    api.patch(`/layout-manager/widgets/${widget.id}`, {
      online: realtime,
    }).then(() => {
      setWidget({
        ...widget,
        isRealtime: realtime,
      });
    });
  };

  const classes = useStyles();

  if (error) {
    return (
      <Box p={6}>
        {error.code && (
        <Typography align="center" variant="h5">{`Error ${error.code}`}</Typography>
        )}
        {error.message && (
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Box fontSize={20}>
            {error.message}
          </Box>
        </Grid>
        )}
      </Box>
    );
  }

  // if (!widget) {
  //   return (
  //     <PageNotFound />
  //   )
  // }

  return widget && (
    <Box bgcolor="background.main" width={1} height={1} display="flex" flexDirection="column">
      {isSmallDisplay ? <SmallDisplayAlert />
        : (
          <>
            <>
              <Box
                width={1}
                p={2}
                display="flex"
                justifyContent={widget.type !== 'value' ? 'space-between' : 'flex-end'}
                height="60px"
              >
                {widget.type !== 'value' && (
                <Box>
                  <RealtimeSwitch
                    isRealtime={widget.isRealtime}
                    changeRealtime={handleRealtimeSwitch}
                  />
                </Box>
                )}
                {/* #1167 Online/offline indicator issues */}
                {/* <Box display="flex" alignItems="center" justifyContent="center"> */}
                {/*  <SvgIcon htmlColor={online ? '#44b700' : 'red'} fontSize="small"> */}
                {/*    {online ? <Wifi /> : <WifiOff />} */}
                {/*  </SvgIcon> */}
                {/* </Box> */}
              </Box>
              <Divider />
            </>
            <div className={classes.widgetContent}>
              {widget.component && (
              <widget.component
                isSharedWidget
                isPieChart={widget?.type === 'pie_chart'}
                selectedChartType={widget?.type}
                {...widget}
              />
              )}
            </div>
          </>
        )}
    </Box>

  );
};

export default SharedWidgets;
